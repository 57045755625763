import React from 'react';

import Button from './item/Button';
import RestrictedTitle from './item/Title';

export default function Carousel({
  attributes, postDataList
}) {
  const {
    carouselIsDisplayArrow,
    carouselIsDisplayIndicatorDot,
    carouselIsDisplayIndicatorThumb
  } = attributes;

  return (
    <>
      <div className='swiper-container digest-carouselSlider__main -full'>
        <div className='swiper-wrapper'>
          {postDataList.map((postData, index) => {
            const {
              thumbnail
            } = postData;

            return (
              <div className='swiper-slide' key={index}>
                <div className='digest-carouselSlider__img'>
                  <img
                    src={thumbnail}
                    alt=""
                  />
                </div>
                <div className='digest-carouselSlider__textContent'>

                  <RestrictedTitle
                    postData={postData}
                    attributes={attributes}
                    titleClass={'digest-carouselSlider__title'}
                  />

                  <Button
                    postData={postData}
                    attributes={attributes}
                    hasLink={true}
                  />

                  <div className='digest-carouselSlider__spacer'></div>
                </div>
              </div>
            )
          })}

        </div>
        {carouselIsDisplayArrow && (
          <div className='digest-carouselSlider__navigation'>
            <span className='swiper-button-prev'></span>
            <span className='swiper-button-next'></span>
          </div>
        )}
        {carouselIsDisplayIndicatorDot && (
          <div className='swiper-pagination'></div>
        )}
      </div>

      {carouselIsDisplayIndicatorThumb && (
        <div className='digest-carouselSlider__thumbArea' data-digest-carousel-thumb>
          <div className='swiper-wrapper'>
            {postDataList.map((postData, index) => {
              const {
                thumbnail
              } = postData;

              return (
                <div className='swiper-slide' key={index}>
                  <div className='imgWrapper'>
                    <span
                      style={{
                        backgroundImage: `url(${thumbnail})`
                      }}
                    ></span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
import React from 'react';

export default function Tags({ attributes, postData}) {
  const {
    selectedPostType,
    isDisplayTag
  } = attributes;
  const {
    tags
  } = postData;
  
  return (
    <>
      {(selectedPostType != 'page' && isDisplayTag) && (
        <div className="follot-digest-card__text3">
          {tags && tags.map((tag, index) => {
            return (
              <span key={index}>{tag.name}</span>
            )
           }
          )}
        </div>
      )}
    </>
  )
}
import React from 'react';

import Anchor from './item/Anchor';
import Author from './item/Author';
import Bg from './item/Bg';
import Button from './item/Button';
import Date from './item/Date';
import Img from './item/Img';
import Label from './item/Label';
import RestrictedTitle from './item/Title';
import RestrictedExcerpt from './item/Excerpt';
import Tags from './item/Tags';
import NewsEventDate from './item/NewsEventDate';
import NewsEventLocate from './item/NewsEventLocate';

export default function CardType(props) {
  const { digestType, attributes, postDataList, digestItemAttributes } = props;

  return (
    <>
      {digestType == 'card_borderd' && (
        postDataList.map((postData, index) => {
          
          const commonProps = {
            postData: postData,
            attributes: attributes
          };

          const { is_news_cat_is_event } = postData;

          const NewCatGroup = () => {
            return (
              <>
                <RestrictedTitle
                  postData={postData}
                  attributes={attributes}
                />
                <NewsEventDate
                  {...commonProps}
                />
                <NewsEventLocate
                  {...commonProps}
                />
                <RestrictedExcerpt
                  postData={postData}
                  attributes={attributes}
                />
              </>
            )
          }

          return (
            <li className={'follot-digest-card__item'}
              key={index}>
              <Bg />
              <Anchor
                className='follot-digest__itemInner'
                postData={postData}
              >
                <Img
                  postData={postData}
                  attributes={attributes}
                  hasLabel={true}
                />

                <div className='follot-digest-card__contentContainer'>
                  {is_news_cat_is_event ? (
                    <>
                      <NewCatGroup />
                    </>
                  ) : (
                      <>
                        <RestrictedTitle
                          postData={postData}
                          attributes={attributes}
                        />
                        <Date
                          {...commonProps}
                        />
                        <RestrictedExcerpt
                          postData={postData}
                          attributes={attributes}
                        />
                        <Author {...commonProps} />
                      </>
                  )}

                  <Button {...commonProps} />
                </div>
              </Anchor>
            </li>
          )
        })
      )}
      {digestType == 'card_noborder' && (
        postDataList.map((postData, index) => {

          const commonProps = {
            postData: postData,
            attributes: attributes
          };

          const { is_news_cat_is_event } = postData;

          return (
            <li className='follot-digest-card__item' key={index}>
              <Bg />
              <Anchor
                className='follot-digest__itemInner'
                postData={postData}
              >
                <Img
                  postData={postData}
                  attributes={attributes}
                  hasLabel={true}
                />

                <div className='follot-digest-card__contentContainer'>
                  {is_news_cat_is_event ? (
                    <NewCatGroup />
                    ) : (
                      <>
                        <RestrictedTitle
                          postData={postData}
                          attributes={attributes}
                        />
                        <Date
                          {...commonProps}
                        />
                        <RestrictedExcerpt
                          postData={postData}
                          attributes={attributes}
                        />
                        <Tags {...commonProps} />
                        <Author {...commonProps} />
                      </>
                  )}

                  <Button {...commonProps} />
                </div>
              </Anchor>
            </li>
          )
        })
      )}
      {/* {digestType == 'card_noborder_rounded' && (
        postDataList.map((postData, index) => {

          const commonProps = {
            postData: postData,
            attributes: attributes
          };

          return (
            <li className='follot-digest-card__item' key={index}>
              <Bg />
              <Anchor
                className='follot-digest__itemInner'
                postData={postData}
              >
                <RestrictedTitle
                  postData={postData}
                  attributes={attributes}
                />
                <RestrictedExcerpt
                  postData={postData}
                  attributes={attributes}
                />
                <Img
                  postData={postData}
                  attributes={attributes}
                />
                <Button {...commonProps} />
              </Anchor>
            </li>
          )
        })
      )} */}
      {digestType == 'card_intext' && (
        postDataList.map((postData, index) => {

          const commonProps = {
            postData: postData,
            attributes: attributes
          };

          return (
            <li className='follot-digest-squareCard__item' key={index}>
              <Anchor
                className='follot-digest__itemInner'
                postData={postData}
              >
                <Label {...commonProps} />
                <Img
                  postData={postData}
                  attributes={attributes}
                  className={'follot-digest-squareCard__imgWrapper'}
                />

                <div className='follot-digest-squareCard__textContainer'>

                  <RestrictedTitle
                    postData={postData}
                    attributes={attributes}
                    titleClass={'follot-digest-squareCard__title'}
                  />

                  <Date
                    {...commonProps}
                  />
                </div>
              </Anchor>
            </li>
          )
        })
      )}
      {digestType == 'list' && (
        postDataList.map((postData, index) => {

          const commonProps = {
            postData: postData,
            attributes: attributes
          };

          const { is_news_cat_is_event } = postData;

          return (
            <li className='article' key={index}>
              <Bg />
              <Anchor
                className='follot-digest__itemInner'
                postData={postData}
              >
                <div className='follot-digest-squareCard'>
                  <div className='follot-digest-squareCard__item' >
                    <Label {...commonProps} />
                    <Img
                      postData={postData}
                      attributes={attributes}
                      className={'follot-digest-squareCard__imgWrapper'}
                      bgClassName={''}
                    />
                  </div>
                </div>

                <div className='articleList__content'>

                  {is_news_cat_is_event ? (
                    <NewCatGroup />
                  ) : (
                    <>
                        <RestrictedTitle
                          postData={postData}
                          attributes={attributes}
                        />

                        <Date
                          {...commonProps}
                        />

                        <RestrictedExcerpt
                          postData={postData}
                          attributes={attributes}
                        />

                        <Author
                          {...commonProps}
                          cssClass={'digest-hogetext'}
                        />
                    </>
                  )}
                </div>

                <Button {...commonProps} />
              </Anchor>
            </li>
          )
        })
      )}
    </>
  );
}
import React from 'react';

export default function NewsEventLocate({
  postData, attributes,
  cssClass = 'digest-news-event-text'
}) {
  const {
    isDisplayNewsLocate
  } = attributes;

  return (
    <>
      {isDisplayNewsLocate && (
        <div className={cssClass}>
          {postData['news_event_location_name']}
        </div>
      )}
    </>
  )
}
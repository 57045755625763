import React from 'react';

import Anchor from './Anchor';

import { trimText } from '../../util';

export default function RestrictedExcerpt(
  { postData, attributes,
    excerptClass = 'follot-digest__excerpt'
  }
) {
  const { text } = postData;
  const {
    excerptNumberOfWords_sm,
    excerptNumberOfWords_md,
    excerptNumberOfWords_lg,
    isDisplayExcerpt
  } = attributes;

  return (
    <>
      {isDisplayExcerpt && (
        <p
        className={excerptClass}
        >
          <span
          >
            <span className='-onlySp'>
              {trimText(text, excerptNumberOfWords_sm)}
            </span>
            <span className='-onlyTab'>
              {trimText(text, excerptNumberOfWords_md)}
            </span>
            <span className='-onlyPc'>
              {trimText(text, excerptNumberOfWords_lg)}
            </span>
          </span>
        </p>
      )}
    </>
  )
}
import React from 'react';

import Anchor from './Anchor';

export default function Button({
  postData, attributes,
}) {
  const {
    isDisplayLinkButton,
    buttonText,
    hasLink = false
  } = attributes

  return (
    <>
      {hasLink ? (
        <Anchor
          className='follot-digest__button'
          postData={postData}
        >
          <span>
            {buttonText}
          </span>
        </Anchor>
      ) : (
        <span
          className='follot-digest__button'
        >
          <span>
            {buttonText}
          </span>
        </span>
      )}
    </>

  )
}
import React from 'react';

export default function Date({ postData, attributes }) {
  const {
    is_new_post,
    date,
    update_date
  } = postData;
  
  const {
    isDisplayDate,
    isDisplayUpdateDate,
    isDisplayBudge,
    updateDateText,
    budgeText
  } = attributes;

  const newPostClassName = is_new_post && isDisplayBudge ? '' : '-hide';
  
  return (
    <>
      {(isDisplayDate || isDisplayUpdateDate) && (
        <div className='follot-digest__date'>
          <span
            className={`follot-digest__dateTag ${newPostClassName}`}
          >
            {budgeText}
          </span>

          {isDisplayDate && (
            <span className='follot-digest__dateDate'>
              {date}
            </span>
          )}
          {isDisplayUpdateDate && (
            <span className='follot-digest__dateDate'>
              {updateDateText + update_date}
            </span>
          )}
        </div>
      )}
    </>
  )
}
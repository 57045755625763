/**
 * mvカルーセルスライダー
 */

import Swiper, { Autoplay, Pagination, Mousewheel, EffectFade, Navigation, Thumbs } from 'swiper';

Swiper.use([Autoplay, Pagination, Mousewheel, EffectFade, Navigation, Thumbs]);



export function digestCarouselSlider() {
    document.querySelectorAll('[data-digest-carousel]').forEach($el => {
        const $container = $el.querySelector('.swiper-container');
        const slides = $container.querySelectorAll('.swiper-slide');

        let thumbs;
        const hasThumbs = $el.querySelector('[data-digest-carousel-thumb]');

        if (hasThumbs) {
            thumbs = new Swiper(hasThumbs, {
                slidesPerView: slides.length,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            });
        }

        const swiperConfig = {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 1,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',  //ページネーションの要素のセレクタ
                type: 'bullets',  //ページネーションのタイプ
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',  //「次へ」ボタンの要素のセレクタ
                prevEl: '.swiper-button-prev',  //「前へ」ボタンの要素のセレクタ
            },
            thumbs: {
                swiper: hasThumbs ? thumbs : null
            },
            breakpoints: {
                // tab
                769: {

                },
                // pc
                993: {
                }
            }
        }

        swiperConfig['speed'] = Number($el.dataset.dcarouselSpeed);

        // オートローテンションと表示時間
        if ($el.dataset.dcarouselAutoplay == "1") {
            swiperConfig['autoplay'] = {
                autoplay: {
                    delay: Number($el.dataset.dcarouselDelay),
                },
            };

            swiperConfig['disableOnInteraction'] == Boolean(Number($el.dataset.disableoninteraction));
        }

        // effect幅設定
        if ($el.dataset.dcarouselEffect == 'fade') {
            swiperConfig['effect'] = 'fade';
        } else if ($el.dataset.dcarouselEffect == '') {
            // エフェクトなし
            swiperConfig['speed'] = 0;
        }

        // 表示枚数の設定
        swiperConfig['slidesPerView'] = Number($el.dataset.dcarouselPerviewsm);
        swiperConfig['breakpoints']['769']['slidesPerView'] = Number($el.dataset.dcarouselPerviewmd);
        swiperConfig['breakpoints']['993']['slidesPerView'] = Number($el.dataset.dcarouselPerviewlg);

        // 動作スライド数(1度のローテーションで何枚動かすか)
        swiperConfig['slidesPerGroup'] = Number($el.dataset.dcarouselPergroupsm);
        swiperConfig['breakpoints']['769']['slidesPerGroup'] = Number($el.dataset.dcarouselPergroupmd);
        swiperConfig['breakpoints']['993']['slidesPerGroup'] = Number($el.dataset.dcarouselPergrouplg);

        const swiper = new Swiper($container, swiperConfig);
        swiper.init();
    });
}

// slidesPerGroup: 3,
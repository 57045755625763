import React from 'react';

export default function NewsEventDate({
  postData, attributes,
  cssClass = 'digest-news-event-text'
}) {
  const {
    isDisplayNewsDate
  } = attributes;

  return (
    <>
      {isDisplayNewsDate && (
        <div className={cssClass}>
          {postData['new_evnet_date_text']}
        </div>
      )}
    </>
  )
}
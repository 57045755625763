import React from 'react';

export default function Anchor({
  children,
  postData,
  className = ''
}) {
  const {
    is_outbound_link,
    permalink
  } = postData;
  
  if (is_outbound_link) {
    return (
      <a className={className} href={permalink} target="_blank" rel="noreferrer noopener">
        {children}
      </a>
    )
  } else {
    return (
      <a className={className} href={permalink}>
        {children}
      </a>
    )
  }
}
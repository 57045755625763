import React from 'react';

import Anchor from './Anchor';

import { trimText } from '../../util';

export default function RestrictedTitle(
  { postData, attributes,
    titleClass = 'follot-digest__title'
  }
) {
  const { title } = postData;
  const {
    titleNumberOfWords_sm,
    titleNumberOfWords_md,
    titleNumberOfWords_lg
  } = attributes;

  return (
    <p
      className={titleClass}
    >
      {/* <Anchor
        postData={postData}
      > */}
      <span>
        <span className='-onlySp'>
          {trimText(title, titleNumberOfWords_sm)}
        </span>
        <span className='-onlyTab'>
          {trimText(title, titleNumberOfWords_md)}
        </span>
        <span className='-onlyPc'>
          {trimText(title, titleNumberOfWords_lg)}
        </span>
      </span>

      {/* </Anchor> */}
    </p>
  )
}
import React from 'react';

import * as ReactDOMClient from 'react-dom/client';
import axios from 'axios';

import DigestContent from './components/DigestContent';
import { digestCarouselSlider } from '../../../../../../src/js/block/digestCarousel';

document.querySelectorAll('.follot-digest').forEach($digest => {
  const $digestMain = $digest.querySelector('[data-digest-type]');

  if (!$digestMain) {
    return;
  }

  const digestType = $digestMain.dataset.digestType;

  const domContainer = digestType == 'carousel' ? $digest.querySelector("[data-digest-type]") : $digest.querySelector("[data-digest-type] ul");
  const root = ReactDOMClient.createRoot(domContainer);

  const postAttributes = JSON.parse($digestMain.dataset.attributes);

  // tab
  $digest.querySelectorAll('.c-tab__button').forEach($button => {
    $button.addEventListener('click', () => {
      const $activeButton = $digest.querySelector('button.is-active');
      $activeButton.classList.remove('is-active');
      $button.classList.add('is-active');
      initButtonEvent($button)
    });
  });

  // pull down
  $digest.querySelectorAll('.selectBoxTab__option').forEach($button => {
    $button.addEventListener('click', () => {
      $digest.querySelectorAll('.selectBoxTab .is-active').forEach($el => {
        $el.classList.remove('is-active');
      });
      initButtonEvent($button);
    });
  });

  function initButtonEvent($button) {

    const termObj = $button.dataset.term != 'all' ? JSON.parse($button.dataset.term) : null;

    const params = createApiParams(postAttributes, termObj);

    axios.get(`/wp-json/follot-api/v1/get_post_data_for_digest?${params}`)
      .then(function (response) {
        // handle success
        root.render(
          <DigestContent
            digestType={digestType}
            attributes={postAttributes}
            postDataList={response.data}
          />
        );

        if (digestType == 'carousel') {
          setTimeout(() => {
            digestCarouselSlider();
          }, 500)
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }
});


function createApiParams(attributes, termObj) {
  // 投稿タイプ
  const postType = `post_type=${attributes.selectedPostType}`;
  console.log(postType);

  // taxnomoy を指定
  const taxonomy = attributes.selectedPostType == 'post' ? 'category' : attributes.selectedPostType + '-category';

  // term
  const term = termObj ? `&term=${termObj.slug}` : '';

  // 並び順の細かい制御はphp側で
  const order = `&order=${attributes.order}`;
  console.log(order);

  const postsLimit = `&per_page=${attributes.postsLimit}`;

  const author = attributes.authorName != '' ? '&author=' + attributes.authorName : '';

  const exclude = `&exclude=${attributes.postNotIn}`;

  return postType + `&taxonomy=${taxonomy}` + term + order + postsLimit + author + exclude;
}
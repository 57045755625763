import React from 'react';

import Anchor from './Anchor';
import Label from './Label';

export default function Img(
  { postData,
    attributes,
    hasLabel = false,
    className = 'follot-digest-card__imgWrapper',
    bgClassName = 'follot-digest-card__imgBg' }
) {

  const {
    category_color,
    category_name,
    is_outbound_link,
    permalink,
    thumbnail
  } = postData;

  const {
    isDisplayImage
  } = attributes;



  return (
    <div className={className}>
      {isDisplayImage && (
        <span
        >
          <span
            className={bgClassName}
            style={{
              backgroundImage: `url(${thumbnail})`}}
          ></span>
        </span>
      )}
      {hasLabel && (
        <Label
          postData={postData}
          attributes={attributes}
        />
      )}
    </div>
  )
}
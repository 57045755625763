import React from 'react';

export default function Author({
  postData, attributes,
  cssClass = 'follot-digest-card__text2'
}) {
  const {
    isDisplayAuthor
  } = attributes;
  
  return (
    <>
      {isDisplayAuthor && (
        <div className={cssClass}>
          {postData['author']}
        </div>
      )}
    </>
  )
}
import React from 'react';

export default function Label({ postData, attributes }) {
  const { category_name, category_color } = postData;
  const { isDisplayCategory } = attributes;

  return (
    <>
      {(category_name && isDisplayCategory) && (
        <span
          className='c-label -half'
          style={{ background: category_color }}>{category_name}
        </span>
      )}
    </>

  )
}
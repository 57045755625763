import React from 'react';

import CardType from './CardType';
import Carousel from './Carousel';

export default function DigestContent(props) {
  const { digestType, attributes, postDataList } = props;

  const digestItemAttributes = {
    'card_borderd': {
    },
    'card_noborder': {
    },
    'card_noborder_rounded': {
    },
    'card_intext': {
    },
    'list': {
    },
    'carousel': {
      
    }
  }

  return (
    <>
      {digestType == 'carousel' ? 
        <Carousel
          attributes={attributes} postDataList={postDataList}
        />
        : (
          <CardType
            digestType={digestType}
            postDataList={postDataList}
            attributes={attributes}
            digestItemAttributes={digestItemAttributes[digestType]}
          /> 
        )
      }
    </>
  );
}